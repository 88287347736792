import React, {useContext, useEffect, useRef, useState} from "react";
import {RootDispatchContext, RootStateContext} from "../../providers/RootProvider";
import {Modal} from 'flowbite'
import {Link} from "react-router-dom";
import {fetchProductList} from "../../actions/server/product";
import toast from "react-hot-toast";

function Home() {
    const rootStateContext = useContext(RootStateContext);
    const rootDispatchContext = useContext(RootDispatchContext);
    const {user} = rootStateContext;
    const productsListModal = useRef(null);
    const [products, setProducts] = useState([]);
    const authIsLoggedIn = user?.auth?.isLoggedIn;

    const parameterColor = {
        "main-primary-color":user?.subsidiary?.parameters["main-primary-color"] ? user?.subsidiary?.parameters["main-primary-color"]["value"] : "#121b44",
        "main-secondary-color":user?.subsidiary?.parameters["main-secondary-color"] ? user?.subsidiary?.parameters["main-secondary-color"]["value"] : "#f45c04",
    }
    useEffect(() => {
        if (authIsLoggedIn){
            fetchProductList(rootDispatchContext,(_products)=>{
                setProducts(_products);
            },(error)=>{
                toast.error(error.message);
            });
        }
    }, [rootDispatchContext,authIsLoggedIn]);

    useEffect(() => {
        const $targetEl = document.getElementById('modalEl');
        const options = {
            placement: 'center',
            backdrop: 'dynamic',
            backdropClasses: 'bg-gray-900/50 fixed inset-0 z-40',
            closable: true
        };
        const instanceOptions = {
            id: 'modalEl',
            override: true
        };
        productsListModal.current = new Modal($targetEl, options, instanceOptions);

        return()=>{
            if (productsListModal.current){
                productsListModal.current.hide();
            }
        }
    }, []);

    const toggleProductsModalHandler = () => {
        productsListModal.current.toggle();
    }


    return (
        <>
            <div className="min-h-[100%] px-4 sm:px-10 flex items-center">
                <div className="max-w-7xl w-full mx-auto py-16">
                    <div className="grid lg:grid-cols-2 justify-center items-center gap-10">
                        <div>
                            <h1 className="md:text-5xl text-4xl font-bold mb-6 md:!leading-[55px]">
                                IcomPro Simulateur
                            </h1>
                            <p className="text-base leading-relaxed">
                                Etre à votre écoute et réagir rapidement à chacun des problèmes que vous remontez sont
                                au
                                coeur de nos préoccupations. A travers des formulaires intuitifs et clairs, IcomPro
                                Simulateur , vous donne la possibilité de signaler tous vos problèmes pour en attendre
                                un
                                traitement
                                rapide en vue de vous satisfaire.</p>
                            <div className="flex flex-wrap gap-y-4 gap-x-8 mt-8">
                                <button onClick={toggleProductsModalHandler}
                                        className="bg-[#112957] hover:bg-[#112957] text-white flex items-center transition-all font-semibold rounded-full px-5 py-4">
                                    Commencer ma simulation
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-[14px] fill-current ml-2"
                                         viewBox="0 0 492.004 492.004">
                                        <path
                                            d="M484.14 226.886 306.46 49.202c-5.072-5.072-11.832-7.856-19.04-7.856-7.216 0-13.972 2.788-19.044 7.856l-16.132 16.136c-5.068 5.064-7.86 11.828-7.86 19.04 0 7.208 2.792 14.2 7.86 19.264L355.9 207.526H26.58C11.732 207.526 0 219.15 0 234.002v22.812c0 14.852 11.732 27.648 26.58 27.648h330.496L252.248 388.926c-5.068 5.072-7.86 11.652-7.86 18.864 0 7.204 2.792 13.88 7.86 18.948l16.132 16.084c5.072 5.072 11.828 7.836 19.044 7.836 7.208 0 13.968-2.8 19.04-7.872l177.68-177.68c5.084-5.088 7.88-11.88 7.86-19.1.016-7.244-2.776-14.04-7.864-19.12z"
                                            data-original="#000000"></path>
                                    </svg>
                                </button>
                                <button
                                    className={"bg-transparent text-["+parameterColor["main-secondary-color"]+"] border-2 border-["+parameterColor["main-secondary-color"]+"] flex items-center transition-all font-semibold rounded-full px-5 py-2"} style={{ background:parameterColor["main-secondary-color"]}}>
                                    Prise en main
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-[14px] fill-current ml-2"
                                         viewBox="0 0 492.004 492.004">
                                        <path
                                            d="M484.14 226.886 306.46 49.202c-5.072-5.072-11.832-7.856-19.04-7.856-7.216 0-13.972 2.788-19.044 7.856l-16.132 16.136c-5.068 5.064-7.86 11.828-7.86 19.04 0 7.208 2.792 14.2 7.86 19.264L355.9 207.526H26.58C11.732 207.526 0 219.15 0 234.002v22.812c0 14.852 11.732 27.648 26.58 27.648h330.496L252.248 388.926c-5.068 5.072-7.86 11.652-7.86 18.864 0 7.204 2.792 13.88 7.86 18.948l16.132 16.084c5.072 5.072 11.828 7.836 19.044 7.836 7.208 0 13.968-2.8 19.04-7.872l177.68-177.68c5.084-5.088 7.88-11.88 7.86-19.1.016-7.244-2.776-14.04-7.864-19.12z"
                                            data-original="#000000"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="max-lg:mt-12 h-full">
                            {/*<img src="https://readymadeui.com/analtsis.webp" alt="banner img"
                                 className="w-full h-full object-cover invisible"/>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div id="modalEl" tabIndex="-1"
                 className="transition fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative w-full max-w-4xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow">
                        <div
                            className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                            <h3 className="text-xl font-medium text-gray-900">
                                Nos produits d'assurance vie
                            </h3>
                            <button type="button" onClick={toggleProductsModalHandler}
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                                    data-modal-hide="large-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                     fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 place-items-center">
                                {products.map((_product,_k)=> (
                                    <div key={_product.uuid}
                                        className="bg-white border border-gray-200 rounded-lg shadow">
                                        <Link to="/simulation/product/epargne">
                                            {_product?.product_parameter["main-product-picture"]?.cdn ? (
                                                <img className="rounded-t-lg"
                                                     src={_product?.product_parameter["main-product-picture"]?.cdn} alt=""/>
                                            ): (
                                                <img className="rounded-t-lg"
                                                     src="/assets/images/products/pension.gif" alt=""/>
                                            )}
                                        </Link>
                                        <div className="p-2">
                                        <Link to={"/simulation/product/"+_product.slug +"/"+_product.uuid}>
                                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                                    {_product?.name}
                                                </h5>
                                            </Link>
                                            <Link to={"/simulation/product/"+_product.slug +"/"+_product.uuid}
                                                  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#112957] rounded-lg hover:bg-[#112957] focus:ring-4 focus:outline-none focus:[#112957]">
                                                Commencer
                                                <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true"
                                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                                    <path stroke="currentColor" strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                                </svg>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
