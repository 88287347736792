import React, {useContext, useEffect, useRef, useState} from "react";
import {RootDispatchContext, RootStateContext} from "../../../../providers/RootProvider";
import AppLoading from "../../../../components/layouts/AppLoading";
import AmountInput from "../../../../components/share/AmountInput";
import toast from "react-hot-toast";
import {Drawer} from "flowbite";
import ReactApexChart from "react-apexcharts";
import {useParams} from "react-router";
import {fetchProductDetails, fetchToProductSimulation} from "../../../../actions/server/product";
import {formatAmount, getChartDta, isValidDate, wait} from "../../../../utilities/common";

function RetraiteDigital(props) {
    const rootStateContext = useContext(RootStateContext);
    const rootDispatchContext = useContext(RootDispatchContext);
    const {user} = rootStateContext;
    const parameterColor = {
        "main-primary-color":user?.subsidiary?.parameters["main-primary-color"] ? user?.subsidiary?.parameters["main-primary-color"]["value"] : "#121b44",
        "main-secondary-color":user?.subsidiary?.parameters["main-secondary-color"] ? user?.subsidiary?.parameters["main-secondary-color"]["value"] : "#f45c04",
    }
    const params = useParams();
    const productUUID = params?.uuid;
    const [productDetail, setProductDetail] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [step, setStep] = useState(1);
    const [isSimulation, setIsSimulation] = useState(false);
    const [simulationResult, setSimulationResult] = useState(null);
    const simulationDrawer = useRef(null);
    const [chartData, setChartData] = useState(null);

    const [formData, setFormData] = useState({
        1: {
            mEffect: {
                value: "", isValid: false, isChanged: false, message: "Veuillez sélectionner le mois de la date d'effet"
            },
            yEffect: {
                value: (new Date()).getFullYear(),
                isValid: false,
                isChanged: false,
                min: (new Date()).getFullYear() - 1,
                max: (new Date()).getFullYear() + 100,
                message: "Veuillez saisir l'année de la date d'effet(Minimum : " + ((new Date()).getFullYear() - 1) + "; maximum : " + ((new Date()).getFullYear() + 100) + ")"
            }, amountPeriodic: {
                value: "",
                isValid: false,
                isChanged: false,
                min: 1000,
                message: "Veuillez saisir le montant de la cotisation périodique"
            }

        }, 2: {
            capitalDeces: {
                value: "0",
                isValid: false,
                isChanged: false,
                notEmpty:true,
                message: "Veuillez saisir le montant du capital décés"
            }, duree: {
                value: "",
                isValid: false,
                isChanged: false,
                min: 5,
                message: "Veuillez saisir la durée de cotisation (Minimum : 5 ans)"
            }

        }, 3: {
            periodicity: {
                value: "12", isValid: true, isChanged: true, min: 1, message: "Veuillez choisir la périodicité"
            }, dob: {
                value: "",
                isValid: false,
                isValidDate: true,
                isChanged: false,
                message: "Veuillez saisir la date de naissance"
            }
        }

    });


    useEffect(() => {
        if (!isLoading) {
            const $targetEl = document.getElementById('modalSimulationEl');
            if ($targetEl) {
                const options = {
                    placement: 'right',
                    closable: true,
                    backdrop: true,
                    bodyScrolling: false,
                    edge: false,
                    edgeOffset: '',
                    onHide: () => {
                        setIsSimulation(false);
                        setChartData(null);
                        setSimulationResult(null);
                    }
                };
                const instanceOptions = {
                    id: 'modalSimulationEl',
                    override: false
                };
                simulationDrawer.current = new Drawer($targetEl, options, instanceOptions);
            }
        }

        return () => {
            if (simulationDrawer.current) {
                simulationDrawer.current.hide();
            }
        }
    }, [isLoading]);

    const toggleSimulationDrawerHandler = () => {
        simulationDrawer.current.toggle();
    }

    const checkIsValidForm = () => {
        let currentFormDataStep = formData[step];
        for (const currentFormDataStepKey in currentFormDataStep) {
            const _kk = currentFormDataStep[currentFormDataStepKey];

            let isValid = _kk.value !== "";
            if (_kk?.notEmpty !== undefined) {
                isValid = _kk.value.toString().trim() !== "";
            }
            if (_kk?.max !== undefined) {
                isValid = _kk.max >= parseInt(_kk.value === "" ? 0 : _kk.value);
            }
            if (_kk?.min !== undefined) {
                isValid = _kk.value !== "" && _kk.min <= parseInt(_kk.value === "" ? 0 : _kk.value);
            }
            if (_kk?.isValidDate !== undefined) {
                isValid =  isValidDate(_kk.value);
            }
            if (isValid === false) {
                handleChange(step, currentFormDataStepKey, {target: {value: ""}});
                toast.error(_kk.message);
                return false;
            } else {
                handleChange(step, currentFormDataStepKey, {target: {value: _kk.value}});
            }
        }
        return true;
    }

    const handleNext = () => {
        if (checkIsValidForm()) {
            setStep((prevState) => prevState + 1);
        }
    };

    const handlePrevious = () => {
        if (!isSimulation) setStep((prevState) => prevState - 1);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (checkIsValidForm()) {
            setIsSimulation(true);
            let dataSimulation = {};
            for (const stepKey in formData) {
                for (const dataKey in formData[stepKey]) {
                    dataSimulation[dataKey] = formData[stepKey][dataKey].value;
                }
            }
            let dataSend = {};
            dataSend["product_uuid"] = productUUID;
            dataSend["group_responsibility_user_id"] = user?.group_responsibility_user?.id;
            dataSend["cover_effect"] = "01/" + dataSimulation.mEffect + "/" + dataSimulation.yEffect;
            dataSend["cotisation"] = dataSimulation.amountPeriodic;
            dataSend["cover_duration"] = dataSimulation.duree;
            dataSend["periodicity"] = dataSimulation.periodicity;
            dataSend["death_capital_subscribed"] = dataSimulation.capitalDeces;
            dataSend["dateOfBirth"] = dataSimulation.dob.split("-").reverse().join("/");
            dataSend["status_id"] = 'enabled';
            dataSend["has_save"] = false;
            const sigle_code = user?.subsidiary?.sigle_code;
            const taskURI = '/simulation/' + sigle_code + '/retraite-digital/run';
            fetchToProductSimulation(rootDispatchContext, taskURI, dataSend, (result) => {
                toggleSimulationDrawerHandler();
                wait(1000).then(()=>{
                    setSimulationResult(result);
                    setChartData(getChartDta(result));
                });
            }, (error) => {
                setIsSimulation(false);
                toast.error(error.message);

            })
        }
    };


    const handleChange = (step, key, eventTarget) => {
        let targetValue = eventTarget.target.value.toString().trim();
        let isValid = targetValue !== "";

        if (formData[step][key]?.notEmpty !== undefined) {
            isValid = targetValue.trim() !== "";
        }
        if (formData[step][key]?.max !== undefined) {
            isValid = formData[step][key].max >= parseInt(targetValue === "" ? 0 : targetValue);
        }
        if (formData[step][key]?.min !== undefined) {
            isValid = formData[step][key].min <= parseInt(targetValue === "" ? 0 : targetValue);
        }
        if (formData[step][key]?.isValidDate !== undefined) {
            isValid = isValidDate(targetValue);
        }
        setFormData((prevState) => {
            return {
                ...prevState, [step]: {
                    ...prevState[step], [key]: {
                        ...prevState[step][key], isChanged: true, isValid: isValid, value: eventTarget.target.value,
                    }
                }
            }
        })

    }

    useEffect(() => {
        if (productUUID) {
            fetchProductDetails(rootDispatchContext, productUUID, (product) => {
                setProductDetail(product);
                setIsLoading(false)
            }, () => {
                toast.error("Impossible de recuperer les le detail du produit");
                setIsLoading(false)
            });
        }
    }, [productUUID,rootDispatchContext]);

    const isInvalidClass = (evaluation) => (evaluation ? " bg-red-50 border border-red-500 text-red-900 placeholder-red-700" : "");

    return (<>
        {isLoading && (
            <div className="justify-center items-center flex bg-white z-50"
                 style={{height: '100%', width: '100%', position: 'absolute', top: 0, left: 0}}>
                <AppLoading isAbsolute={true}/>
            </div>
        )}
        <div className="lg:min-h-[560px] px-4 sm:px-10 max-[600px]:bg-sky-300">
            <div className="max-w-4xl w-full mx-auto py-16">
                <p className="text-4xl font-bold text-gray-900 text-center mb-8">
                    {productDetail?.name}
                </p>
                <div className="">
                    <div className="flex justify-between mb-1">
                        <span className="text-base font-medium text-[#]">Progression</span>
                        <span
                            className="bg-[#112957] text-white text-sm font-medium inline-flex items-center px-2.5 py-0.5 rounded border border-[#112957]">
                            <svg className="w-2.5 h-2.5 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                 fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z"/>
                            </svg>
                            {Math.round((step / 3) * 100)}%
                        </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div className="bg-[#112957] h-2.5 rounded-full"
                             style={{width: Math.round((step / 3) * 100) + '%'}}></div>
                    </div>
                </div>
                <form action="#" className="mt-5" onSubmit={handleSubmit}>
                    <div className={(step === 1 ? "animate-slideInRight block" : "hidden")}>
                        <div className="pt-5">
                            <h3 className="mb-2 text-lg font-medium leading-none text-gray-900">
                                Date d'effet souhaité
                            </h3>
                            <div className="grid gap-4 mb-4 sm:grid-cols-2">
                                <div>
                                    <label htmlFor="mEffect"
                                           className="block mb-2 text-sm text-gray-700">Mois :</label>
                                    <select onChange={(eventTarget) => handleChange(1, 'mEffect', eventTarget)}
                                            id="mEffect" defaultValue={formData[1].mEffect.value}
                                            className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" + isInvalidClass(!formData[1].mEffect.isValid && formData[1].mEffect.isChanged)}>
                                        <option value="">Choisissez un mois</option>
                                        <option value="01">Janvier</option>
                                        <option value="02">Février</option>
                                        <option value="03">Mars</option>
                                        <option value="04">Avril</option>
                                        <option value="05">Mai</option>
                                        <option value="06">Juin</option>
                                        <option value="07">Juillet</option>
                                        <option value="08">Août</option>
                                        <option value="09">Septembre</option>
                                        <option value="10">Octobre</option>
                                        <option value="11">Novembre</option>
                                        <option value="12">Décembre</option>
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="yEffect"
                                           className="block mb-2 text-sm text-gray-700">Année :</label>
                                    <input onChange={(eventTarget) => handleChange(1, 'yEffect', eventTarget)}
                                           value={formData[1].yEffect.value}
                                           type="number" name="yEffect" id="yEffect"
                                           className={"bg-gray-50 text-center border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" + isInvalidClass(!formData[1].yEffect.isValid && formData[1].yEffect.isChanged)}
                                           placeholder={"Ex : " + (new Date().getFullYear())} required={true}/>
                                </div>
                            </div>

                        </div>
                        <div className="pt-5">
                            <h3 className="mb-2 text-lg font-medium leading-none text-gray-900">
                                Cotisation périodique (hors accessoires)
                            </h3>
                            <div className="grid gap-4 mb-4 sm:grid-cols-1">
                                <div>
                                    <label htmlFor="amountPeriodic"
                                           className="block mb-2 text-sm text-gray-700">Montant :</label>
                                    <AmountInput onChange={handleChange}
                                                 step={1}
                                                 value={formData[1].amountPeriodic.value}
                                                 className={"bg-gray-50 text-center border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" + isInvalidClass(!formData[1].amountPeriodic.isValid && formData[1].amountPeriodic.isChanged)}
                                                 placeholder="Saisir un montant" required={true}
                                                 name="amountPeriodic"
                                                 id="amountPeriodic"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={(step === 2 ? "animate-slideInRight block" : "hidden")}>
                        <div className="pt-5">
                            <h3 className="mb-2 text-lg font-medium leading-none text-gray-900">
                                Capital décès souscrit
                            </h3>
                            <div className="grid gap-4 mb-4 sm:grid-cols-1">
                                <div>
                                    <label htmlFor="capitalDeces"
                                           className="block mb-2 text-sm text-gray-700">Montant :</label>
                                    <AmountInput onChange={handleChange}
                                                 step={2}
                                                 value={formData[2].capitalDeces.value}
                                                 className={"bg-gray-50 text-center border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" + isInvalidClass(!formData[2].capitalDeces.isValid && formData[2].capitalDeces.isChanged)}
                                                 placeholder="Saisir un montant" required={true} name="capitalDeces"
                                                 id="capitalDeces"/>
                                </div>
                            </div>
                        </div>
                        <div className="pt-5">
                            <h3 className="mb-2 text-lg font-medium leading-none text-gray-900">
                                Durée de cotisation
                            </h3>
                            <div className="grid gap-4 mb-4 sm:grid-cols-1">
                                <div>
                                    <label htmlFor="duree"
                                           className="block mb-2 text-sm text-gray-700">Durée(En Année)
                                        :</label>
                                    <input
                                        onChange={(eventTarget) => handleChange(2, 'duree', eventTarget)}
                                        value={formData[2].duree.value}
                                        type="number" name="duree" id="duree"
                                        className={"bg-gray-50 text-center border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" + isInvalidClass(!formData[2].duree.isValid && formData[2].duree.isChanged)}
                                        placeholder="Saisir une durée supérieur à 5" required={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={(step === 3 ? "animate-slideInRight block" : "hidden")}>
                        <div className="pt-5">
                            <h3 className="mb-4 text-lg font-medium leading-none text-gray-900">
                                Périodicité de paiement.
                            </h3>
                            <ul className="grid w-full gap-2 md:grid-cols-4 pb-5">
                                <li>
                                    <input type="radio" id="periodicity-mensuel" name="periodicity" value="12"
                                           onChange={(eventTarget) => handleChange(3, 'periodicity', eventTarget)}
                                           className="hidden peer" defaultChecked={true}/>
                                    <label htmlFor="periodicity-mensuel"
                                           className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:bg-[#112957] peer-checked:border-white peer-checked:text-white hover:text-white hover:bg-[#112957]">
                                        <div className="block">
                                            <div className="w-full text-lg font-semibold">Mensuel</div>
                                            <div className="w-full">Chaque mois</div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                             fill="currentColor" className="w-5 h-5 ms-3">
                                            <path
                                                d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"/>
                                            <path fillRule="evenodd"
                                                  d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id="periodicity-third" name="periodicity" value="4"
                                           onChange={(eventTarget) => handleChange(3, 'periodicity', eventTarget)}
                                           className="hidden peer" defaultChecked={false}/>
                                    <label htmlFor="periodicity-third"
                                           className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:bg-[#112957] peer-checked:border-white peer-checked:text-white hover:text-white hover:bg-[#112957]">
                                        <div className="block">
                                            <div className="w-full text-lg font-semibold">Trimestrielle</div>
                                            <div className="w-full">Chaque 3 mois</div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                             fill="currentColor" className="w-5 h-5 ms-3">
                                            <path
                                                d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"/>
                                            <path fillRule="evenodd"
                                                  d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </label>
                                </li>
                                <li>
                                    <input type="radio" id="periodicity-semestre" name="periodicity" value="2"
                                           onChange={(eventTarget) => handleChange(3, 'periodicity', eventTarget)}
                                           className="hidden peer" defaultChecked={false}/>
                                    <label htmlFor="periodicity-semestre"
                                           className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:bg-[#112957] peer-checked:border-white peer-checked:text-white hover:text-white hover:bg-[#112957]">
                                        <div className="block">
                                            <div className="w-full text-lg font-semibold">Semestrielle</div>
                                            <div className="w-full">Chaque 6 mois</div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                             fill="currentColor" className="w-5 h-5 ms-3">
                                            <path
                                                d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"/>
                                            <path fillRule="evenodd"
                                                  d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </label>
                                </li>
                                <li>
                                    <input
                                        onChange={(eventTarget) => handleChange(3, 'periodicity', eventTarget)}
                                        type="radio" id="periodicity-annual" name="periodicity" value="1"
                                        className="hidden peer" defaultChecked={false}/>
                                    <label htmlFor="periodicity-annual"
                                           className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:bg-[#112957] peer-checked:border-white peer-checked:text-white hover:text-white hover:bg-[#112957]">
                                        <div className="block">
                                            <div className="w-full text-lg font-semibold">Annuelle</div>
                                            <div className="w-full">Chaque année</div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                             fill="currentColor" className="w-5 h-5 ms-3">
                                            <path
                                                d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"/>
                                            <path fillRule="evenodd"
                                                  d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="pt-5">
                            <h3 className="mb-2 text-lg font-medium leading-none text-gray-900">
                                Votre date de naissance
                            </h3>
                            <div className="grid gap-4 mb-4 sm:grid-cols-1">
                                <div>
                                    <label htmlFor="dob"
                                           className="block mb-2 text-sm text-gray-700">Date de naissance
                                        :</label>
                                    <input onChange={(eventTarget) => handleChange(3, 'dob', eventTarget)}
                                           value={formData[3].dob.value}
                                           type="date" name="dob" id="dob"
                                           className={"bg-gray-50 text-center border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" + isInvalidClass(!formData[3].dob.isValid && formData[3].dob.isChanged)}
                                           placeholder={"Ex : 01/01/1980"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-2 justify-center items-center gap-10 mt-10">
                        {step > 1 && (<button type="button" onClick={handlePrevious}
                                              className="flex items-center justify-center gap-x-1 text-white bg-[#f45c04] hover:bg-[#f45c04] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center" style={{background:parameterColor["main-secondary-color"]}}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="size-6">
                                <path fillRule="evenodd"
                                      d="M7.28 7.72a.75.75 0 0 1 0 1.06l-2.47 2.47H21a.75.75 0 0 1 0 1.5H4.81l2.47 2.47a.75.75 0 1 1-1.06 1.06l-3.75-3.75a.75.75 0 0 1 0-1.06l3.75-3.75a.75.75 0 0 1 1.06 0Z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span className="text-2xl">Précédent</span>
                        </button>)}
                        {step < 3 ? (<button type="button" onClick={handleNext}
                                             className="flex items-center justify-center gap-x-1 text-white bg-[#112957] hover:bg-[#112957] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center">
                                <span className="text-2xl">
                                    Suivant
                                </span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="size-6">
                                <path fillRule="evenodd"
                                      d="M16.72 7.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l2.47-2.47H3a.75.75 0 0 1 0-1.5h16.19l-2.47-2.47a.75.75 0 0 1 0-1.06Z"
                                      clipRule="evenodd"/>
                            </svg>
                        </button>) : (isSimulation ? <button disabled type="button"
                                                             className="text-white bg-[#112957] hover:bg-[#112957] focus:ring-4 focus:ring-[#112957] font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 justify-center inline-flex items-center">
                            <svg aria-hidden="true" role="status"
                                 className="inline w-4 h-4 me-3 text-white animate-spin"
                                 viewBox="0 0 100 101"
                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="#E5E7EB"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentColor"/>
                            </svg>
                            <span className="text-2xl">Simulation en cours...</span>
                        </button> : <button type="button" onClick={handleSubmit}
                                            className="text-white bg-[#112957] hover:bg-[#112957] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center">

                            <span className="text-2xl">Enregistrer</span>
                        </button>)}

                    </div>
                </form>

            </div>
        </div>
        <div id="modalSimulationEl"
             className="top-0 fixed top-[70px] right-0 z-40 h-[calc(100vh-70px)] p-4 overflow-y-auto transition-transform translate-x-full bg-white w-[calc(100vw-30%)]"
             tabIndex="-1" aria-labelledby="drawer-right-label">
            <div className="border-b border-gray-400">
                <h3 id="drawer-right-label"
                    className="inline-flex items-center mb-4 text-2xl font-medium text-gray-900">
                    <svg className="w-4 h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                         fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    Résultat simulation : {productDetail?.name}
                </h3>
                <button onClick={toggleSimulationDrawerHandler} type="button" data-drawer-hide="modalSimulationEl"
                        aria-controls="modalSimulationEl"
                        className="text-gray-400 bg-gray-200 text-gray-900 rounded-full text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center">
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span className="sr-only">Close menu</span>
                </button>
            </div>
            {simulationResult ? (
                <div>
                    <p className="flex items-center text-4xl font-extrabold justify-center my-5">
                        Prime Total : <span
                        className="bg-red-100 border text-red-800 border-red-600 text-4xl font-semibold me-2 px-2.5 py-0.5 rounded ms-2">
                    {formatAmount(simulationResult?.total_prime)}
                </span>
                    </p>
                    <div className="grid grid-cols-2 gap-4">
                        <p className="flex items-center text-xl font-extrabold justify-center my-2">
                            Prime Nette : <span
                            className="bg-blue-100 text-blue-800 text-xl font-semibold me-2 px-2.5 py-0.5 rounded">
                        {formatAmount(simulationResult?.net_prime)}</span>
                        </p>
                        <p className="flex items-center text-xl font-extrabold justify-center my-2">
                            Prime Base : <span
                            className="bg-blue-100 text-blue-800 text-xl font-semibold me-2 px-2.5 py-0.5 rounded">
                        {formatAmount(simulationResult?.base_prime)}</span>
                        </p>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <p className="flex items-center text-xl font-extrabold justify-center my-2">
                            Cumul Cotisations Epargne : <span
                            className="bg-blue-100 text-blue-800 text-xl font-semibold me-2 px-2.5 py-0.5 rounded">
                        {formatAmount(simulationResult?.amount_contributions)}
                    </span>
                        </p>
                        <p className="flex items-center text-xl font-extrabold justify-center my-2">
                            Montant à terme : <span
                            className="bg-blue-100 text-blue-800 text-xl font-semibold me-2 px-2.5 py-0.5 rounded">
                        {formatAmount(simulationResult?.amount_maturity)}
                    </span>
                        </p>
                    </div>
                    <div className="w-full border-t border-gray-300 mt-3 pt-2 justify-center items-center">
                        <div style={{width: '95%'}}>
                            {chartData && (
                                <ReactApexChart options={chartData.options} series={chartData.series} type="bar"
                                                width="100%"
                                                height={400}/>
                            )}
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-4 mt-3">
                        <button type="button"
                                className="justify-center text-white bg-[#FF9119] hover:bg-[#FF9119]/80 focus:ring-4 focus:outline-none focus:ring-[#FF9119]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="w-4 h-4 me-2 -ms-1">
                                <path fillRule="evenodd"
                                      d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                                      clipRule="evenodd"/>
                            </svg>
                            Appeler un conseiller
                        </button>
                        <button type="button"
                                className="justify-center text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="w-4 h-4 me-2 -ms-1">
                                <path
                                    d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z"/>
                            </svg>

                            Me transmettre par mail
                        </button>
                        <button type="button"
                                className="justify-center text-white bg-[#2557D6] hover:bg-[#2557D6]/90 focus:ring-4 focus:ring-[#2557D6]/50 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="w-5 h-5 me-2 -ms-1">
                                <path fillRule="evenodd"
                                      d="M7.502 6h7.128A3.375 3.375 0 0 1 18 9.375v9.375a3 3 0 0 0 3-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 0 0-.673-.05A3 3 0 0 0 15 1.5h-1.5a3 3 0 0 0-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6ZM13.5 3A1.5 1.5 0 0 0 12 4.5h4.5A1.5 1.5 0 0 0 15 3h-1.5Z"
                                      clipRule="evenodd"/>
                                <path fillRule="evenodd"
                                      d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V9.375Zm9.586 4.594a.75.75 0 0 0-1.172-.938l-2.476 3.096-.908-.907a.75.75 0 0 0-1.06 1.06l1.5 1.5a.75.75 0 0 0 1.116-.062l3-3.75Z"
                                      clipRule="evenodd"/>
                            </svg>
                            Souscrire
                        </button>
                        <button type="button" onClick={toggleSimulationDrawerHandler}
                                className="justify-center text-white bg-[#050708] hover:bg-[#050708]/80 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="w-5 h-5 me-2 -ms-1">
                                <path
                                    d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375Z"/>
                                <path fillRule="evenodd"
                                      d="m3.087 9 .54 9.176A3 3 0 0 0 6.62 21h10.757a3 3 0 0 0 2.995-2.824L20.913 9H3.087Zm6.133 2.845a.75.75 0 0 1 1.06 0l1.72 1.72 1.72-1.72a.75.75 0 1 1 1.06 1.06l-1.72 1.72 1.72 1.72a.75.75 0 1 1-1.06 1.06L12 15.685l-1.72 1.72a.75.75 0 1 1-1.06-1.06l1.72-1.72-1.72-1.72a.75.75 0 0 1 0-1.06Z"
                                      clipRule="evenodd"/>
                            </svg>
                            Quitter
                        </button>
                    </div>
                </div>
            ) : (
                <div className="justify-center items-center flex bg-white" style={{height: 'calc(100% / 1.1)'}}>
                    <AppLoading isAbsolute={false}/>
                </div>
            )}
        </div>
    </>);
}

export default RetraiteDigital;
